<template>
  <table class="table">
    <thead>
      <tr>
        <th class="is-textleft">活動名稱</th>
        <th class="is-textright">點數</th>
        <th class="is-textright">餘額</th>
        <th class="is-textright">到期日</th>
      </tr>
    </thead>
    <tbody>
      <template v-if="earnedPoints.length === 0">
        <tr>
          <td colspan="14">
            <div class="notification is-center">
              <strong>目前尚無任何點數贈送記錄</strong>
            </div>
          </td>
        </tr>
      </template>
      <tr v-for="earnedPoint in earnedPoints" :key="earnedPoint.earnedId">
        <td class="is-textleft">
          {{ earnedPoint.project === null ? "-" : earnedPoint.project.project }}
        </td>
        <td class="is-textright">+{{ earnedPoint.points.toLocaleString() }}</td>
        <td class="is-textright">{{ earnedPoint.balance.toLocaleString() }}</td>
        <td class="is-textright">{{ earnedPoint.expiry }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "EarnedPointTable.vue",
  props: {
    earnedPoints: {
      type: Array,
      require: true,
    },
  },
};
</script>
